import { Link } from "gatsby";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { SiStrava } from "@react-icons/all-files/si/SiStrava";
import { SiYoutube } from "@react-icons/all-files/si/SiYoutube";

import PropTypes from "prop-types";
import React from "react";
import "./header.scss";

const Header = ({ siteTitle }) => (
  <header>
    <div 
      style={{
        margin: `0px auto`,
        maxWidth: `960px`,
      }}>

      <h2 style={{
        padding: `5px 25px`,
        backgroundColor: `#fafafa`,
        fontSize: `1rem`,
      }}>
        <Link
          to="/"
          style={{textDecoration: `none`}}
        >
          {siteTitle}
        </Link>
      </h2>

      <div className="header-inner">
      
        <h1>
          NEVER
          <br></br>
          <em>TOO</em>
          <br></br>
          SERIOUS
        </h1>

        <div style={{
          padding: `5px 25px`,
        }}>
          <p>Keeping myself accountable with a silly little website. I won the 2021 Riding Goals challenge for <a href="https://www.instagram.com/p/CLO46PjFWCG/">@rapha_uk</a> and now I actually have to do them. I'm sharing my progress here, Panny D permitting. Never <em>too</em> serious though.</p>
        
          <div className="social-container">
            <a className="social" href="http://strava.com/athletes/beckybicycle"><SiStrava /></a>
            <a className="social" href="http://instagram.com/beckybicycle"><SiInstagram/></a>
            <a className="social" href="https://www.youtube.com/channel/UCOoV4IRFLYi95xYFgpkvsKw"><SiYoutube/></a>
          </div>
        </div>

      </div>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
